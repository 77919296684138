// src/components/RestAreas.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Chip,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import { useParams, useNavigate  } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';

const RestAreas = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [restareas, setRestAreas] = useState([]);
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRestArea, setCurrentRestArea] = useState(null);
  const { control, handleSubmit, reset, setValue, getValues } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchRestAreas();
    fetchServices();
  }, []);

  const fetchRestAreas = async () => {
    const token = localStorage.getItem('adminToken');
    try {
      const response = await axios.get(
        `${apiUrl}/admins/stopzones/${id}/restareas`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRestAreas(response.data);
    } catch (error) {
      console.error('Error fetching restareas:', error);
    }
  };

  const fetchServices = async () => {
    const token = localStorage.getItem('adminToken');
    try {
      const response = await axios.get(`${apiUrl}/admins/services`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const handleOpen = (restarea = null) => {
    setCurrentRestArea(restarea);
    if (restarea) {
      reset({
        friendlyName: restarea.friendlyName,
        price: restarea.price,
        services: restarea.restareas_services.map((rs) => rs.servicesId),
      });
    } else {
      reset({
        friendlyName: '',
        price: '',
        services: [],
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRestArea(null);
    reset();
  };

  const handleSave = async (data) => {
    const token = localStorage.getItem('adminToken');
    try {
      const payload = {
        friendlyName: data.friendlyName,
        price: parseFloat(data.price),
        services: data.services,
      };
      if (currentRestArea) {
        await axios.put(
          `${apiUrl}/admins/stopzones/${id}/restareas/${currentRestArea.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(
          `${apiUrl}/admins/stopzones/${id}/restareas`,
          {
            ...payload,
            stopzonesId: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      fetchRestAreas();
      handleClose();
    } catch (error) {
      console.error('Error saving restarea:', error);
    }
  };

  /*const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/api/restareas/${id}`);
      fetchRestAreas();
    } catch (error) {
      console.error('Error deleting restarea:', error);
    }
  };*/

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton onClick={() => navigate(-1)} sx={{ mb: 1 }}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Piazzole per l'Area di Sosta
      </Typography>
      {/* <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Aggiungi Piazzola
      </Button> */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome Piazzola</TableCell>
              <TableCell>Prezzo</TableCell>
              <TableCell>Servizi Abilitati</TableCell>
              <TableCell>Azioni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restareas.map((restarea) => {
              const sortedServices = restarea.restareas_services
                .map((rs) => rs.services?.name)
                .filter((name) => name)
                .sort();
              return (
                <TableRow key={restarea.id}>
                  <TableCell>{restarea.friendlyName}</TableCell>
                  <TableCell>{restarea.price} €</TableCell>
                  <TableCell>
                    {sortedServices.map((serviceName, index) => (
                      <Chip
                        style={{ margin: '2px' }}
                        key={index}
                        label={serviceName}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleOpen(restarea)}
                      sx={{ mr: 1 }}
                    >
                      Modifica
                    </Button>
                    {/* <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDelete(restarea.id)}
                  >
                    Elimina
                  </Button> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentRestArea ? 'Modifica Piazzola' : 'Aggiungi Piazzola'}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(handleSave)}
            sx={{ mt: 2 }}
          >
            <Controller
              name="friendlyName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome Piazzola"
                  fullWidth
                  margin="dense"
                />
              )}
            />
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Prezzo"
                  fullWidth
                  margin="dense"
                  type="number"
                />
              )}
            />
            <FormGroup>
            <Typography variant="body1" sx={{ mt: 2 }}>Servizi abilitati:</Typography>
              {services.map((service) => (
                <FormControlLabel
                  key={service.id}
                  control={
                    <Controller
                      name="services"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={service.id}
                          checked={
                            getValues('services')?.includes(service.id) || false
                          }
                          onChange={(e) => {
                            const newValue = e.target.checked
                              ? [...getValues('services'), service.id]
                              : getValues('services').filter(
                                  (id) => id !== service.id
                                );
                            setValue('services', newValue);
                          }}
                        />
                      )}
                    />
                  }
                  label={service.name}
                />
              ))}
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annulla
          </Button>
          <Button onClick={handleSubmit(handleSave)} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RestAreas;
