// src/pages/Bookings.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import axios from 'axios';
import BookingTable from '../components/BookingTable';

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [stops, setStops] = useState([]);
  const [users, setUsers] = useState([]);
  const [pitches, setPitches] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchBookings();
    fetchStops();
    fetchUsers();
    fetchPitches();
  }, []);

  const fetchBookings = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`${apiUrl}/admins/bookings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBookings(response.data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const fetchStops = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`${apiUrl}/admins/stops`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStops(response.data);
    } catch (error) {
      console.error('Error fetching stops:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`${apiUrl}/admins/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response.data);
      fetchVehicles(response.data); // Fetch vehicles after fetching users
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchPitches = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`${apiUrl}/admins/restareas`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPitches(response.data);
    } catch (error) {
      console.error('Error fetching pitches:', error);
    }
  };

  const fetchVehicles = async (users) => {
    try {
      const token = localStorage.getItem('adminToken');
      const allVehicles = await Promise.all(
        users.map(async (user) => {
          const response = await axios.get(`${apiUrl}/admins/${user.id}/vehicles`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return { userId: user.id, vehicles: response.data };
        })
      );
      setVehicles(allVehicles);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
    }
  };

  const handleAdd = async (newBooking) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(`${apiUrl}/admins/bookings`, newBooking, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBookings([...bookings, response.data]);
    } catch (error) {
      console.error('Error adding booking:', error);
    }
  };

  /*const handleEdit = async (updatedBooking) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.put(
        `/api/bookings/${updatedBooking.id}`,
        updatedBooking,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBookings(
        bookings.map((booking) =>
          booking.id === updatedBooking.id ? response.data : booking
        )
      );
    } catch (error) {
      console.error('Error updating booking:', error);
    }
  };*/

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('adminToken');
      await axios.delete(`${apiUrl}/admins/bookings/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBookings(bookings.filter((booking) => booking.id !== id));
    } catch (error) {
      console.error('Error deleting booking:', error);
    }
  };

  const handleAddUser = async (newUser) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.post(`${apiUrl}/admins/users`, newUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers([...users, response.data]);
      return response.data;
    } catch (error) {
      console.error('Error adding user:', error);
      throw error;
    }
  };

  const handleAddVehicle = async (vehicleData) => {
    try {
      const response = await axios.post(`${apiUrl}/admins/vehicles`, vehicleData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
      });
      return response.data;  // Restituisci il veicolo creato
    } catch (error) {
      console.error('Error adding vehicle:', error);
      throw error;
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Prenotazioni
      </Typography>
      <Paper sx={{ p: 2, mb: 3 }}>
        <BookingTable
          bookings={bookings}
          stops={stops}
          getBookings={fetchBookings}
          onAdd={handleAdd}
          //onEdit={handleEdit}
          onDelete={handleDelete}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          users={users}
          addUser={handleAddUser}
          addVehicle={handleAddVehicle}
          pitches={pitches}
          vehicles={vehicles}
          getVehicles={fetchVehicles}
        />
      </Paper>
    </Box>
  );
};

export default Bookings;
