import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';

const AdminLogin = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/admin/login`, {
        email,
        password,
      });
      localStorage.setItem('adminToken', response.data.token);
      setIsAuthenticated(true);
      navigate('/bookings');
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Aggiunta del logo */}
        <Box sx={{ mb: 3 }}>
          <img
            src="/logo192.png" // Cambia il percorso con il tuo logo
            alt="Logo"
            style={{ maxWidth: '100%', height: 'auto', borderRadius: '8%' }} // Il logo sarà responsivo
          />
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography component="h1" variant="h5" textAlign={'center'}>
            Area Sosta Camper
          </Typography>
          <Typography component="h1" variant="h5" textAlign={'center'}>
            Corinaldo
          </Typography>
        </Box>
        {error && <Alert severity="error">{error}</Alert>}
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Indirizzo E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AdminLogin;
