// src/components/Sidebar.js
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookingsIcon from '@mui/icons-material/EventAvailable';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LogoutIcon from '@mui/icons-material/Logout';

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
      }}
    >
      {/* <Toolbar /> */}
      {/* Logo responsive */}
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <img
          src="/logo192.png" // Cambia il percorso con il tuo logo
          alt="Logo"
          style={{ maxWidth: '50%', height: 'auto', borderRadius: '8%' }} // Il logo sarà responsivo
        />
      </Box>
      <List>
        <ListItem button component={RouterLink} to="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={RouterLink} to="/bookings">
          <ListItemIcon>
            <BookingsIcon />
          </ListItemIcon>
          <ListItemText primary="Prenotazioni" />
        </ListItem>
        <ListItem button component={RouterLink} to="/stopzones">
          <ListItemIcon>
            <FmdGoodIcon />
          </ListItemIcon>
          <ListItemText primary="Aree sosta" />
        </ListItem>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<LogoutIcon />}
          onClick={handleLogout}
          fullWidth
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
