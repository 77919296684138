// src/components/StopZones.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';

const StopZones = () => {
  const [stopzones, setStopZones] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentStopZone, setCurrentStopZone] = useState(null);
  const { control, handleSubmit, reset } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchStopZones();
  }, []);

  const fetchStopZones = async () => {
    try {
      const token = localStorage.getItem('adminToken');

      const response = await axios.get(`${apiUrl}/admins/stopzones`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setStopZones(response.data);
    } catch (error) {
      console.error('Error fetching stopzones:', error);
    }
  };

  const handleOpen = (stopzone = null) => {
    setCurrentStopZone(stopzone);
    if (stopzone) {
      reset(stopzone);
    } else {
      reset({
        name: '',
        description: '',
        address: '',
        latitude: '',
        longitude: '',
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentStopZone(null);
    reset();
  };

  const handleSave = async (data) => {
    try {
      const token = localStorage.getItem('adminToken');
      if (currentStopZone) {
        await axios.put(
          `${apiUrl}/admins/stopzones/${currentStopZone.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axios.post(`${apiUrl}/admins/stopzones`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
      fetchStopZones();
      handleClose();
    } catch (error) {
      console.error('Error saving stopzone:', error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Aree di Sosta
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Aggiungi Area di Sosta
      </Button>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Descrizione</TableCell>
              <TableCell>Indirizzo</TableCell>
              <TableCell>Latitudine</TableCell>
              <TableCell>Longitudine</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stopzones.map((stopzone) => (
              <TableRow key={stopzone.id}>
                <TableCell>{stopzone.name}</TableCell>
                <TableCell>{stopzone.description}</TableCell>
                <TableCell>{stopzone.address}</TableCell>
                <TableCell>{stopzone.latitude}</TableCell>
                <TableCell>{stopzone.longitude}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleOpen(stopzone)}
                    sx={{ mr: 1 }}
                  >
                    Modifica
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/stopzones/${stopzone.id}`)}
                  >
                    Vedi Piazzole
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {currentStopZone
            ? 'Modifica Area di Sosta'
            : 'Aggiungi Area di Sosta'}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(handleSave)}
            sx={{ mt: 2 }}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Nome" fullWidth margin="dense" />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descrizione"
                  fullWidth
                  margin="dense"
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Indirizzo"
                  fullWidth
                  margin="dense"
                />
              )}
            />
            <Controller
              name="latitude"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Latitudine"
                  fullWidth
                  margin="dense"
                />
              )}
            />
            <Controller
              name="longitude"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Longitudine"
                  fullWidth
                  margin="dense"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annulla
          </Button>
          <Button onClick={handleSubmit(handleSave)} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StopZones;
