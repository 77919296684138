import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Button,
  Autocomplete,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Mese da 1 a 12
  const day = String(now.getDate()).padStart(2, '0'); // Giorno del mese
  const hours = String(now.getHours()).padStart(2, '0'); // Ore
  const minutes = String(now.getMinutes()).padStart(2, '0'); // Minuti

  // Formatta la stringa nel formato 'YYYY-MM-DDTHH:mm'
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const BookingModal = ({
  open,
  booking,
  users,
  addUser,
  addVehicle,
  pitches,
  vehicles,
  stops,
  onClose,
  onSave,
}) => {
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm({
    defaultValues: {
      userId: 'new',
      firstName: booking ? booking.users.firstName : '',
      lastName: booking ? booking.users.lastName : '',
      phone: booking ? booking.users.phone : '',
      prefix: booking ? booking.users.prefix : '+39',
      pitch: booking ? booking.restareas.friendlyName : '',
      pitchId: booking ? booking.restareas.id : '',
      start: booking
        ? new Date(booking.start * 1000).toISOString().slice(0, 16)
        : getCurrentDateTime(), // Imposta data e ora attuali
      duration: booking ? (booking.end - booking.start) / (24 * 60 * 60) : 1,
      price: booking ? booking.price : 0,
      vehicle: booking ? booking.vehicles.licensePlate : '',
      vehicleId: booking ? booking.vehicles.id : '',
      licensePlate: booking ? booking.vehicles.licensePlate : '',
      pricePerDay: booking ? booking.restareas.price : 0,
      adults: booking ? booking.adults : 1, // Numero di adulti (default 1)
      childrens: booking ? booking.childrens : 0, // Numero di bambini (default 0)
      restareasId: booking ? booking.restareasId : '', // ID della restarea
      stopzonesId: booking ? booking.stopzonesId : '', // ID della stopzone
      paymentType: booking ? booking.paymentType : 'contanti', // Metodo di pagamento
    },
  });

  const [isNewUser, setIsNewUser] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userVehicles, setUserVehicles] = useState([]);

  // Monitor duration and pricePerDay to calculate the total price
  const duration = watch('duration');
  const pricePerDay = watch('pricePerDay');

  useEffect(() => {
    if (!booking) {
      reset({
        userId: booking ? booking.users.id : 'new',
        firstName: booking ? booking.users.firstName : '',
        lastName: booking ? booking.users.lastName : '',
        phone: booking ? booking.users.phone : '',
        prefix: booking ? booking.users.prefix : '+39',
        pitch: booking ? booking.restareas.friendlyName : '',
        pitchId: booking ? booking.restareas.id : '',
        start: booking
          ? new Date(booking.start * 1000).toISOString().slice(0, 16)
          : getCurrentDateTime(), // Imposta data e ora attuali
        duration: booking ? (booking.end - booking.start) / (24 * 60 * 60) : 1,
        price: booking ? booking.price : 0,
        vehicle: booking ? booking.vehicles.licensePlate : '',
        vehicleId: booking ? booking.vehicles.id : '',
        licensePlate: booking ? booking.vehicles.licensePlate : '',
        pricePerDay: booking ? booking.restareas.price : 0,
        adults: booking ? booking.adults : 1,
        childrens: booking ? booking.childrens : 0,
        restareasId: booking ? booking.restareasId : '',
        stopzonesId: booking ? booking.stopzonesId : '',
        paymentType: booking ? booking.paymentType : 'contanti',
      });
    }
    setIsNewUser(!booking);
    if (booking) {
      setUserVehicles(
        vehicles.find((v) => v.userId === booking.users.id)?.vehicles || []
      );
    }
  }, [booking, reset, vehicles]);

  useEffect(() => {
    // Calcola il prezzo totale in base alla durata e al prezzo giornaliero
    const totalPrice = duration * pricePerDay;

    // Assicurati di aggiornare il campo 'price' nel form
    if (!isNaN(totalPrice)) {
      setValue('price', totalPrice);
    }
  }, [duration, pricePerDay, setValue]);

  const isOverlapping = (newStart, newEnd, existingStart, existingEnd) => {
    // Condizione 1: Il nuovo inizio è tra l'inizio e la fine di una prenotazione esistente
    if (newStart >= existingStart && newStart < existingEnd) {
      return true;
    }

    // Condizione 2: La nuova fine è tra l'inizio e la fine di una prenotazione esistente
    if (newEnd > existingStart && newEnd <= existingEnd) {
      return true;
    }

    // Condizione 3: La nuova prenotazione avvolge completamente una prenotazione esistente
    if (newStart <= existingStart && newEnd >= existingEnd) {
      return true;
    }

    return false;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const startUnix = new Date(data.start).getTime() / 1000;
    const endUnix = startUnix + data.duration * 86400;

    // Assicurati che adults e children siano numeri interi
    const adults = parseInt(data.adults, 10);
    const childrens = parseInt(data.childrens, 10);

    // Dati dell'utente
    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      prefix: data.prefix,
    };

    const overlappingStop = stops
      .filter((stop) => stop.restareasId === data.restareasId) // Filtra per piazzola (restareasId)
      .find((stop) => {
        return isOverlapping(startUnix, endUnix, stop.start, stop.end);
      });
    if (overlappingStop) {
      alert("La prenotazione si accavalla con un'altra prenotazione."); // Puoi anche fare un ritorno o bloccare l'inserimento
      setLoading(false);
      return;
    }

    try {
      let userId = data.userId;

      // Crea l'utente se è nuovo
      if (isNewUser) {
        // Controlla se esiste già un utente con lo stesso numero di telefono
        const existingUser = users.find((u) => u.phone === data.phone);
        if (existingUser) {
          alert('Esiste già un utente con lo stesso numero di telefono.');
          setLoading(false);
          return;
        } else {
          const newUser = await addUser(user);
          userId = newUser.id;
        }
      }

      // Cerca se esiste un veicolo con la stessa targa per l'utente
      let existingVehicle = vehicles
        .find((v) => v.userId === userId) // Trova il set di veicoli per l'utente
        ?.vehicles.find(
          (vehicle) => vehicle.licensePlate === data.licensePlate
        ); // Cerca la targa nell'array vehicles

      let vehicleId;

      // Dati del veicolo
      if (existingVehicle) {
        vehicleId = existingVehicle.id; // Se esiste, usa il suo ID
      } else {
        const newVehicle = await addVehicle({
          nationality: 'Italia',
          licensePlate: data.licensePlate,
          carName: 'Mia auto',
          userId: userId,
        });
        vehicleId = newVehicle.id;
      }

      try {
        // Logica per salvare i dati (ad esempio, chiamata API)
        onSave({
          ...data,
          adults: adults, // Salva come intero
          childrens: childrens, // Salva come intero
          userId: userId,
          vehicleId,
          start: startUnix,
          end: endUnix,
          paymentType: data.paymentType,
        });
      } catch (error) {
        console.error('Error saving booking:', error);
      } finally {
        setLoading(false); // Imposta loading a false dopo aver completato il salvataggio
      }
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };

  const handleUserChange = (event, value) => {
    if (value === null || value.id === 'new') {
      setIsNewUser(true);
      reset({
        userId: 'new',
        firstName: '',
        lastName: '',
        phone: '',
        prefix: '+39',
        pitch: '',
        pitchId: '',
        start: '',
        duration: 1,
        price: '',
        vehicle: '',
        vehicleId: '',
        licensePlate: '',
        pricePerDay: 0,
        adults: 1,
        childrens: 0,
        restareasId: '',
        stopzonesId: '',
        paymentType: '',
      });
      setUserVehicles([]);
    } else {
      setIsNewUser(false);
      const user = users.find((u) => u.id === value.id);
      setValue('userId', user.id);
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('phone', user.phone);
      setValue('prefix', user.prefix || '+39');
      setUserVehicles(
        vehicles.find((v) => v.userId === user.id)?.vehicles || []
      );
    }
  };

  const handlePitchChange = (event, value) => {
    if (value !== null) {
      console.log('Pitch:', value);
      setValue('pitch', value.friendlyName);
      setValue('pitchId', value.id);
      setValue('pricePerDay', value.price);
      setValue('restareasId', value.id); // Imposta l'ID della restarea
      setValue('stopzonesId', value.stopzonesId); // Imposta l'ID della stopzone
    }
  };

  const handleVehicleChange = (event, value) => {
    if (value !== null) {
      setValue('licensePlate', value.licensePlate);
      setValue('vehicleId', value.id);
    }
  };

  const validateStartDate = (value) => {
    return dayjs(value).isValid() || 'Data e Ora di Inizio non validi';
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {booking ? 'Modifica Prenotazione' : 'Aggiungi Prenotazione'}
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="userId"
            control={control}
            rules={{ required: 'Utente obbligatorio' }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={[
                  {
                    id: 'new',
                    firstName: 'Crea',
                    lastName: 'Nuovo Utente',
                    phone: '',
                  },
                  ...users,
                ]}
                getOptionLabel={(option) =>
                  option &&
                  option.firstName !== undefined &&
                  option.lastName !== undefined
                    ? option.id === 'new'
                      ? option.firstName
                      : `${option.firstName} ${option.lastName} (${option.phone})`
                    : ''
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleUserChange}
                value={
                  users.find((u) => u.id === getValues('userId')) || {
                    id: 'new',
                    firstName: 'Crea',
                    lastName: 'Nuovo Utente',
                    phone: '',
                  }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleziona Utente"
                    fullWidth
                    margin="dense"
                  />
                )}
              />
            )}
          />
          {isNewUser && (
            <>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: 'Nome obbligatorio' }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Nome"
                    fullWidth
                    margin="dense"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name="lastName"
                control={control}
                rules={{ required: 'Cognome obbligatorio' }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Cognome"
                    fullWidth
                    margin="dense"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    name="prefix"
                    control={control}
                    rules={{ required: 'Prefisso obbligatorio' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Prefisso"
                        fullWidth
                        margin="dense"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{ required: 'Telefono obbligatorio' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Telefono"
                        fullWidth
                        margin="dense"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Controller
                name="licensePlate"
                control={control}
                rules={{ required: 'Targa obbligatoria' }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Targa Veicolo"
                    fullWidth
                    margin="dense"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </>
          )}
          {!isNewUser && (
            <Controller
              name="vehicleId"
              control={control}
              rules={{ required: 'Veicolo obbligatorio' }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={userVehicles}
                  getOptionLabel={(option) =>
                    option && option.licensePlate !== undefined
                      ? option.licensePlate
                      : ''
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={handleVehicleChange}
                  value={
                    userVehicles.find((v) => v.id === getValues('vehicleId')) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleziona Veicolo"
                      fullWidth
                      margin="dense"
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />
          )}
          <Controller
            name="pitch"
            control={control}
            rules={{ required: 'Piazzola obbligatoria' }}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                options={pitches}
                getOptionLabel={(option) =>
                  option && option.friendlyName !== undefined
                    ? option.friendlyName
                    : ''
                }
                // Confronta i valori degli oggetti in base all'ID
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, value) => {
                  handlePitchChange(_, value);
                  field.onChange(value); // Assicurati che il valore selezionato venga aggiornato correttamente
                }}
                value={
                  pitches.find((p) => p.id === getValues('pitchId')) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleziona Piazzola"
                    fullWidth
                    margin="dense"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            name="start"
            control={control}
            rules={{ validate: validateStartDate }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Data e Ora di Inizio"
                fullWidth
                margin="dense"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          <FormControl fullWidth margin="dense">
            <Controller
              name="duration"
              control={control}
              rules={{ required: 'Durata obbligatoria' }}
              render={({ field, fieldState }) => (
                <FormControl
                  fullWidth
                  margin="dense"
                  error={!!fieldState.error}
                >
                  <InputLabel id="duration-label">Durata</InputLabel>
                  <Select {...field} labelId="duration-label" label="Durata">
                    {[...Array(7).keys()].map((day) => (
                      <MenuItem key={day + 1} value={day + 1}>
                        {day + 1} giorn{day + 1 > 1 ? 'i' : 'o'}
                      </MenuItem>
                    ))}
                  </Select>
                  <p style={{ color: 'red', margin: 0 }}>
                    {fieldState.error?.message}
                  </p>
                </FormControl>
              )}
            />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="adults"
                control={control}
                rules={{ required: 'Numero di Adulti obbligatorio', min: 1 }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Numero di Adulti"
                    fullWidth
                    margin="dense"
                    type="number"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="childrens"
                control={control}
                rules={{ required: 'Numero di Bambini obbligatorio', min: 0 }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label="Numero di Bambini (0-14)"
                    fullWidth
                    margin="dense"
                    type="number"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="paymentType"
            control={control}
            defaultValue="contanti" // Imposta un valore predefinito
            rules={{ required: 'Metodo di pagamento obbligatorio' }}
            render={({ field, fieldState }) => (
              <FormControl fullWidth margin="dense" error={!!fieldState.error}>
                <InputLabel id="payment-type-label">
                  Metodo di Pagamento
                </InputLabel>
                <Select
                  {...field}
                  labelId="payment-type-label"
                  label="Metodo di Pagamento"
                >
                  <MenuItem value="bonifico">Bonifico</MenuItem>
                  <MenuItem value="contanti">Contanti</MenuItem>
                  <MenuItem value="carta">Carta</MenuItem>
                </Select>
                {fieldState.error && (
                  <p style={{ color: 'red', margin: 0 }}>
                    {fieldState.error.message}
                  </p>
                )}
              </FormControl>
            )}
          />

          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Prezzo Totale"
                fullWidth
                margin="dense"
                type="number"
                value={getValues('price') || 0}
                disabled
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Annulla
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          disabled={loading} // Disabilita il pulsante durante il caricamento
        >
          {loading ? 'Salvando...' : 'Salva'}{' '}
          {/* Cambia il testo in base allo stato */}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingModal;
