import { createTheme } from '@mui/material/styles';

// Colore predefinito nel caso il manifest non sia disponibile
const defaultThemeColor = '#ff5722'; 

// Funzione per ottenere il colore dal manifest o utilizzare un colore di default
const getThemeColor = async () => {
  try {
    const response = await fetch('/manifest.json');
    const manifest = await response.json();
    return manifest.theme_color || defaultThemeColor;
  } catch (error) {
    console.error('Errore nel caricare il manifest:', error);
    return defaultThemeColor;
  }
};

export const generateTheme = async () => {
  const themeColor = await getThemeColor();

  return createTheme({
    palette: {
      primary: {
        main: themeColor,
      },
      secondary: {
        main: '#795548',
      },
    },
  });
};
